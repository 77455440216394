import './style.css';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/dist/sweetalert2.css'; // Import styles if needed


// Firebase configuration
const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

// Initialize Firebase and Firestore
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Handle form submission
document.getElementById('subscribeForm')?.addEventListener('submit', async (e) => {
  e.preventDefault();

  const email = document.getElementById('email').value;
  const consent = document.querySelector('input[type="checkbox"]').checked;

  if (!email || !consent) {
    Swal.fire({
      title: 'Error',
      text: 'Please provide your email and consent to the privacy policy.',
      icon: 'error',
      confirmButtonColor: '#f59e0b',
    });
    return;
  }

  try {
    // Save subscriber data to Firestore
    const docRef = await addDoc(collection(db, 'subscribers'), {
      email,
      consent,
      timestamp: new Date(),
    });

    console.log('Document written with ID:', docRef.id);

    Swal.fire({
      title: 'Thank you!',
      text: 'You have been successfully added to our waitlist.',
      icon: 'success',
      confirmButtonColor: '#f59e0b',
    });

    // Clear form inputs
    document.getElementById('email').value = '';
    document.querySelector('input[type="checkbox"]').checked = false;
  } catch (error) {
    console.error('Error adding subscriber:', error);

    Swal.fire({
      title: 'Oops...',
      text: 'Something went wrong. Please try again later.',
      icon: 'error',
      confirmButtonColor: '#f59e0b',
    });
  }
});
